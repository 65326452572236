import styled from 'styled-components'
import familyCenterIcon from '../../../assets/image/kita/detail/familyCenter.png'
import familyCenterIconHover from '../../../assets/image/kita/detail/familyCenter-hover.png'
import discoverVirtuallyIcon from '../../../assets/image/kita/detail/discover-virtually-hover.png'
import discoverVirtuallyIconHover from '../../../assets/image/kita/detail/discover-virtually.png'
import discoverVirtuallyIconKitaOverview from '../../../assets/image/kita/detail/discover-virtually-kitaoverview.png'
import backIcon from '../../../assets/image/generic/white-arrow-left.png'
import exitIcon from '../../../assets/image/kita/detail/exit.svg'
import exitIconHover from '../../../assets/image/kita/detail/exit-hover.svg'
import cityIcon from '../../../assets/image/kita/detail/location.svg'
import cityIconHover from '../../../assets/image/kita/detail/location-hover.svg'
import cityIconKitaOverview from '../../../assets/image/kita/detail/location-kitaoverview.svg'
import cityIconHoverKitaOverview from '../../../assets/image/kita/detail/location-hover-kitaoverview.svg'
import { Container } from '@think-internet/ui-components'

export const StyledDetail = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1003;
  left: 0px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled(Container)`
  height: fit-content;
  border-radius: 30px;
  background-color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.lightorange : theme.color.background.darkBlue)};
  padding: 25px 50px;
  box-sizing: border-box;
  position: relative;
  max-height: calc(100vh - 30px);
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: calc(100vh - 30px);
    width: calc(100vw - 30px);
    padding: 20px;
    overflow: scroll;
  }
`

export const Split = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const Page = styled.div`
  width: 100%;
`

export const City = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
  &:hover {
    .city-icon {
      background-image: url(${({ mode }) => (mode === 'kitaoverview' ? cityIconHoverKitaOverview : cityIconHover)});
    }
    .city-name {
      color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.secondary : theme.color.font.orange)};
    }
  }
`

export const CityIcon = styled.div`
  height: 50px;
  width: 40px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${({ mode }) => (mode === 'kitaoverview' ? cityIconKitaOverview : cityIcon)});
`

export const CityName = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.primary : theme.color.font.secondary)};
`

export const LocationName = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: 'bold';
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.primary : theme.color.font.secondary)};
  margin-top: 15px;
`

export const Availability = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  margin-top: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const AvailabilityLabel = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.primary : theme.color.font.secondary)};
`

export const ThatsUs = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  margin-top: 25px;
`

export const LabelThatsUsLabel = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.primary : theme.color.font.secondary)};
`

export const Arrow = styled.img`
  height: 30px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateX(10px);
  }
`

export const Description = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.primary : theme.color.font.secondary)};
  margin-top: 15px;
  white-space: pre-wrap;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
`

export const ContactPerson = styled.div`
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 0px;
  }
`

export const Image = styled.div`
  background-image: url(${({ src }) => src});
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 30px;
`

export const Name = styled.div`
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.primary : theme.color.font.secondary)};
  font-family: 'bold';
`
export const Title = styled.div`
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.primary : theme.color.font.secondary)};
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const Property = styled.div``

export const PropertyLabel = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.primary : theme.color.font.secondary)};
  margin-top: 15px;
`

export const PropertyValue = styled.a`
  display: block;
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.primary : theme.color.font.secondary)};
  text-decoration: none;
`

export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  gap: 30px;
`

export const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  flex-direction: column;
  width: 60%;
  min-width: fit-content;
  gap: 10px;
`

export const DiscoverVirtually = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: fit-content;
  gap: 10px;
  padding: 12.5px 15px;
  border-radius: 30px;
  border: 2px solid ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.white)};
  cursor: pointer;
  &:hover {
    .icon {
      background-image: url(${({ mode }) => (mode === 'kitaoverview' ? discoverVirtuallyIcon : discoverVirtuallyIconHover)});
    }
    .label {
      color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.secondary : theme.color.font.orange)};
    }
    background-color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.darkBlue)};
    border: 2px solid ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.orange)};
  }
`

export const DiscoverVirtuallyLabel = styled.div`
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.darkBlue : theme.color.font.secondary)};
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const DiscoverVirtuallyIcon = styled.div`
  background-image: url(${({ mode }) => (mode === 'kitaoverview' ? discoverVirtuallyIconKitaOverview : discoverVirtuallyIcon)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 30px;
  height: 20px;
`

export const FamilyCenterButton = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: fit-content;
  gap: 10px;
  padding: 12.5px 15px;
  border-radius: 30px;
  border: 2px solid ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.white)};
  cursor: pointer;
  &:hover {
    .icon {
      background-image: url(${({ mode }) => (mode === 'kitaoverview' ? familyCenterIcon : familyCenterIconHover)});
    }
    .label {
      color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.secondary : theme.color.font.orange)};
    }
    background-color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.darkBlue)};
    border: 2px solid ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.orange)};
  }
`

export const FamilyCenterButtonLabel = styled.div`
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.darkBlue : theme.color.font.secondary)};
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const ContactNow = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 12.5px 15px;
  background-color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.white)};
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.secondary : theme.color.font.orange)};
  border-radius: 30px;
  width: 100%;
  border: 2px solid ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.white)};
  font-size: ${({ theme }) => theme.font.size.sm};
  &:hover {
    background-color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.lightorange : theme.color.background.darkBlue)};
    color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.darkBlue : theme.color.font.orange)};
    border: 2px solid ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.orange)};
  }
`

export const ExternalSignup = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 12.5px 15px;
  border-radius: 30px;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.sm};
  border: 2px solid ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.white)};
  color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.darkBlue : theme.color.font.secondary)};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.darkBlue)};
    border: 2px solid ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.background.darkBlue : theme.color.background.orange)};
    color: ${({ theme, mode }) => (mode === 'kitaoverview' ? theme.color.font.secondary : theme.color.font.orange)};
  }
`

export const Back = styled.div`
  margin: 25px auto 0;
  width: fit-content;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 20px 10px 50px;
  background-image: url(${backIcon});
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 20px center;
  border-radius: 30px;
  border: 2px solid ${({ theme }) => theme.color.background.white};
  color: ${({ theme }) => theme.color.font.secondary};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.sm};
  cursor: pointer;
`

export const Exit = styled.div`
  background-image: url(${exitIcon});
  height: 40px;
  width: 40px;
  background-size: cover;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  &:hover {
    background-image: url(${exitIconHover});
  }
`

export const FZLogo = styled.img`
  height: 75px;
`
